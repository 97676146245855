<template>
    <section class="container is-max-desktop">
        <b-breadcrumb
        size="is-small"
        align="is-left"
    >
        <b-breadcrumb-item tag="router-link" to="/">Home</b-breadcrumb-item>
        <b-breadcrumb-item
            tag="router-link"
            to="/cultural-tours"
            active
        >
            Cultural Tours
        </b-breadcrumb-item>
    </b-breadcrumb>
    
        <h1 class="has-text-weight-semibold mb-5 is-size-4">
            Cultural Tours
          </h1>
    
        <div v-if="similarActivities.length" class="mb-3 mt-4">
            <TicketsAndActivitiesContainer
              is-full-width
              :is-experiences-ids="false"
              :Experiences="similarActivities"
              :Tickets="[]"
            />
            <RequestTour class="mt-3"/>
          </div>
          <p class="mb-3 is-size-5 has-text-weight-semibold mb-5 mt-6">Other Categories</p>
    <CategoriesList/>
        
    </section>
    </template>
    
    <script>
    import { mapActions, mapState } from "vuex";
    import {
        ActivitiesActions,
      ActivitiesStates,
    } from "../store/Storetypes";
    import TicketsAndActivitiesContainer from "../components/TicketsAndActivitiesContainer.vue";
    import RequestTour from "../components/elements/RequestTour.vue";
    import CategoriesList from "./CategoriesList.vue";
    export default{
        components: {
        TicketsAndActivitiesContainer,
        RequestTour,
        CategoriesList
      },
      metaInfo() {
        return {
          title: "Discover Portugal's Traditions with Our Cultural Tours",
          titleTemplate: `Discover Portugal's Traditions with Our Cultural Tours | Guiders `,
          keywords: "cultural tours in Portugal, Portugal cultural experiences, best cultural tours Portugal, Portugal heritage tours, top cultural sightseeing Portugal, Portugal traditional tours, cultural immersion tours Portugal",
          meta: [
            {
              vmid: "description",
              name: "description",
              content: "Dive into Portugal's vibrant culture with our specialized cultural tours. From traditional Fado music to culinary delights, experience the essence of Portugal. Book now for an unforgettable journey!",
            },
            {
              vmid: "og:title",
              property: "og:title",
              content: `Discover Portugal's Traditions with Our Cultural Tours | Guiders `,
            },
            {
              vmid: "og:description",
              property: "og:description",
              content: "Dive into Portugal's vibrant culture with our specialized cultural tours. From traditional Fado music to culinary delights, experience the essence of Portugal. Book now for an unforgettable journey!",
            },
            {
              vmid: "og:url",
              property: "og:url",
              content: "https://guiders.pt/tours/cultural-tours",
            },
          ],
          link: [{ rel: "canonical", href: "https://guiders.pt/tours/cultural-tours" }],
        };
      },
        mounted() {
            window.scrollTo(0, 0);
            this.getActivities()
      },
      computed: {
        ...mapState({
          activities: (state) => state.activities[ActivitiesStates.ACTIVITIES] }),
          similarActivities() {
          const activityId = 5
          const otherActivities = this.activities.filter(
            (activity) => activity.categories.includes(activityId)
          );
          return otherActivities
        },
    },
      methods: {
        ...mapActions({
                getActivities: ActivitiesActions.GET_ACTIVITIES,
            }),
        
      }
    }
    </script>
    
    <style lang="scss" scoped>
    
    ::v-deep{
        .breadcrumb.is-small {
        font-size: 0.9rem;
    }
    
    .breadcrumb a {
        color: #167df0;
    }
    }
    
    </style>